/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'

import API from '../api'

export interface UsersMe {
  account: {
    logo: string
    name: string
  }
  user: {
    id: number
    email: string
    first_name: string
    last_name: string
    role: string
  }
  permission: {
    [key: string]: {
      [key: string]: boolean
    }
  }

  employee: {
    id: undefined | null | number
    display_name: undefined | null | string
    slug: undefined | null | string
  }

  time_zone_id: string
}

export const getUsersMe = async (): Promise<AxiosResponse<UsersMe>> => {
  return API.get<UsersMe>('/v1/users/me')
}
